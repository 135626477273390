<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    text: { type: String, required: false },
  },
});
</script>

<template>
  <div class="rounded-lg shadow-xl">
    <slot />
    <div class="py-3 bg-white">
      <slot name="bottom" />
      <label v-if="text" class="w-full ml-4 text-lg text-gray-700">
        {{ text }}
      </label>
    </div>
  </div>
</template>